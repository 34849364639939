@import "/src/styles/mixin";

.modal {
  width: 100%;
  max-width: 996px;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 20px 20px;
  }

  .modal_container {
    @media screen and (max-width: 768px) {
      padding: 0 0;
    }
  }
}

.wrap {
  width: 100%;
  max-width: 996px;
  display: flex;
  flex-direction: row;
  gap: 30px;

  .list {
    max-height: 576px;
    width: 100%;
    gap: 12px;
    max-width: 310px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 0 10px 0 0;

    .accordion {
      height: 100%;
      max-height: 535px;

      & > li {
        border-bottom: none;
      }

      & > div {
        border-color: var(--grey-lines-color);

        & > h3 {
          & > button {
            padding: 12px 0;
          }
        }

        & > div {
          & > div {
            & > div {
              padding-bottom: 0;
            }
          }
        }
      }

      @media screen and (max-width: 768px) {
        max-height: calc(100vh - 186px);
      }
    }
  }

  .title {
    @include font20;
  }

  .map {
    width: 100%;
    height: 576px;
    border-radius: var(--border-radius-normal);
    overflow: hidden;

    .marker_wrap {
      position: relative;

      .custom_marker {
        position: absolute;
        z-index: 1;
        left: -10px;
        top: -24px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .list,
    .map {
      display: none;
      width: 100%;
      max-width: 100%;
      padding: 0 0;
      max-height: calc(100vh - 186px);
    }
    .visible {
      display: flex;
    }
  }

  @media screen and (max-width: 768px) {
    min-width: 100%;
    width: calc(100vw - 100px);
    max-width: 384px;
    justify-content: start;
  }

  @media screen and (max-width: 460px) {
    min-width: 100%;
    width: calc(100vw - 50px);
    min-height: calc(100vh - 50px);
  }
}

.item {
  position: relative;
  padding-left: 16px;

  & {
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background-color: var(--green-color);
  }
}

.text,
.link {
  @include font14;
}

.link {
  @include lineHover;
}
